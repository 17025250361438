@import "./src/assets/styles/variables";

.awards {
	position: absolute;
	right: 20px;
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	background-color: $white;
	block-size: 100%;
	border-radius: $border-radius;
	box-shadow: $card-shadow;
	inline-size: 100%;
	max-inline-size: 17.8125rem;
	min-block-size: 25.75rem;
	padding-block: 2.375rem;
	padding-inline: 2.375rem 2rem;
	@media screen and (max-width: $lg) {
		margin-block: 0;
		margin-inline: 50% auto;
		padding-inline: 2rem;
	}
	@media screen and (max-width: 54.25rem) {
		bottom: -2.75rem;
		right: 1.5625rem;
		max-width: 14.9375rem;
		margin: 0;
		padding: 1.5625rem;
		min-block-size: 19.125rem;
		height: unset;
	}
	@media screen and (max-width: 42.5rem) {
		position: relative;
		margin-inline: auto;
		order: 3;
		bottom: unset;
		right: unset;
		transform: none;
		width: 100%;
		min-block-size: unset;
		max-width: 21.5625rem;
		margin-bottom: 1.25rem;
		margin-inline: auto;
	}
	@media screen and (max-width: $sm) {
		padding: 2.125rem;
		min-height: 24.1875rem;
		margin-bottom: 3.3125rem;
	}

	&__name {
		margin-block: 0 0.4375rem;
		margin-inline: 0;
		@media screen and (max-width: 54.25rem) {
			font-size: 1rem;
			margin-bottom: 0.125rem;
		}
		@media screen and (max-width: $sm) {
			margin-block: 0 0.25rem;
			font-size: 1.125rem;
			margin-bottom: 0.625rem;
		}
	}

	&__competention{
		inline-size: 90%;
		margin-block: 0 1.5625rem;
		margin-inline: 0;
		@media screen and (max-width: 54.25rem) {
			inline-size: 100%;
			font-size: 0.725rem;
			margin-bottom: 0.625rem;
		}
		@media screen and (max-width: $sm) {
			margin-block: 0 1.2rem;
			font-size: 0.875rem;
		}
	}

	&__button {
		margin-block-end: 1.875rem;
		@media screen and  (max-width: 54.25rem) {
			margin-bottom: 1.125rem;
		}
		@media screen and (max-width: $sm) {
			margin-bottom: 1.8125rem;
			& button {
				font-size: 0.875rem;
			}
		}
	}

	&__slider {
		overflow: hidden;
		flex-grow: 1;
		background-color: hsl(200, 56%, 95%, 0.5);
		border-radius: $border-radius;
		box-shadow: $blue-shadow;
		display: flex;
		align-items: stretch;
	}

	&__slider-wrapper {
		display: flex;
		align-items: stretch;
		align-self: stretch;
		&.swiper-wrapper {
			height: auto;
		}
	}

	&__slider-card {
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		background-color: $white;
		border-radius: $border-radius;
		height: 100%;
		align-self: stretch;
		justify-self: stretch;
		min-height: 5.5rem;
		@media screen and (max-width: 42.5rem) {
			min-height: 6.25rem;
		}
		@media screen and (max-width: 23.375rem) {
			min-height: 5.5rem;
		}
	}

	&__image {
		aspect-ratio: 0;
		inline-size: 68%;
		@media screen and (max-width: $md) {
			inline-size: 52%;
		}
		@media screen and (max-width: $sm) {
			max-inline-size: 4.25rem;
		}
	}

	&__slider-controls {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 0.3125rem;
		margin-block: 1.875rem;
		margin-inline: 0;
		@media screen and (max-width: 54.25rem) {
			margin-block: 1.1875rem 1.125rem;
		}
		@media screen and (max-width: $sm) {
			margin-block-start: 1.625rem;
			margin-block-end: 1.1875rem;
		}
	}

	&__slider-name {
		flex-grow: 1;
		font-size: 0.875rem;
		letter-spacing: -0.03125rem;
		line-height: 100%;
		font-weight: 500;
	}

	&__slider-button {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $light-grey;
		block-size: 1.25rem;
		border-radius: 10rem;
		inline-size: 1.25rem;
		transition: color 300ms ease, background-color 300ms ease;
		&:hover{
			background-color: $primary-color;
			color: $white;
		}
		&:disabled {
			opacity: 0.5;
			background-color: $light-grey;
			color: $light-black;
		}
	}

	&.two-members {
		block-size: fit-content;
		@media screen and (max-width: $lg) {
			max-inline-size: 16.8125rem;
		}
		@media screen and (max-width: 56.25rem) {
			position: relative;
			margin-inline: auto;
			order: 3;
			bottom: unset;
			right: unset;
			transform: none;
			width: 100%;
			min-block-size: unset;
			max-width: 21.5625rem;
			margin-bottom: 1.25rem;
			margin-inline: auto;
		}
		@media screen and (max-width: $sm) {
			padding: 2.125rem;
			min-height: 24.1875rem;
			margin-bottom: 3.3125rem;
		}
		& .awards__slider {
			min-height: 7rem;
		}
	}
}
