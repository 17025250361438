//common styles
@import "./src/assets/styles/variables";
@import "./src/assets/styles/sliders";
@import "./src/assets/styles/reset";
@import "./src/assets/styles/fonts";
@import "./src/assets/styles/base";
@import "./src/assets/styles/stars";
//section styles
@import "./src/components/app/menu/menu";
@import "./src/components/app/mobile-menu/mobile-menu";
@import "./src/components/app/header/header";
@import "./src/components/sections/hero-main-page/hero-main-page";
@import "./src/components/sections/meet/meet";
@import "./src/components/sections/thrive/thrive";
@import "./src/components/sections/calculator/calculator";
@import "./src/components/sections/reviews/reviews";
@import "./src/components/sections/experience/experience";
@import "./src/components/sections/buyside/buyside";
@import "./src/components/sections/faq-main-page/faq-main-page";
@import "./src/components/sections/faq/faq";
@import "./src/components/sections/tabs/tabs";
@import "./src/components/sections/articles/articles";
@import "./src/components/app/footer/footer";
@import "./src/components/sliders/reviews-slider/reviews-slider";
