.reviews-slide {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 2.5rem;
	row-gap: 1rem;
	@media screen and (max-width: $md) {
		padding: 30px;
		inline-size: 500px;
	}

	&__avatar {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		background-color: $primary-color;
		block-size: 3.75rem;
		border-radius: 50%;
		inline-size: 3.75rem;
		margin-inline-end: 1.25rem;
		@media (max-width: 1000px) {
			block-size: 3rem;
			inline-size: 3rem;
		}
		@media (max-width: $md) {
			block-size: 3.75rem;
			inline-size: 3.75rem;
		}
	}

	&__avatar-text-content {
		color: hsl(0, 0%, 100%);
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		letter-spacing: -0.03125rem;
		line-height: 100%;
		text-align: center;
		text-transform: uppercase;
	}

	&__user {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		inline-size: 100%;
		margin-block: 0 2px;
		margin-inline: 0;
		@media screen and (max-width: $sm) {
			flex-flow: row wrap;
			row-gap: 25px;
		}
	}

	&__user-main,
	&__user-additional {
		position: absolute;
		display: flex;
		flex: 1 1;
		flex-flow: column;
		justify-content: space-between;
		inset-block-start: 50%;
		min-inline-size: 100%;
		transform: translate(0, -50%);

		@media screen and (max-width: $sm) {
			position: unset;
			flex-shrink: 0;
			min-inline-size: calc(100% - 100px);
			transform: unset;
		}
	}

	&__user-main {
		inset-inline-start: 80px;
		@media screen and (max-width: $md) {
			inset-inline-start: 80px;
		}
		@media screen and (max-width: $sm) {
			inset-inline-start: unset;
		}
	}

	&__user-additional {
		align-items: flex-end;
		inset-inline-end: 0;
		@media screen and (max-width: $sm) {
			align-items: flex-start;
			inset-inline-end: unset;
		}
	}

	&__info {
		font-weight: 600;
		&--name {
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: -0.5px;
			line-height: 100%;
			margin-block-end: 0.3rem;
		}
		&--state {
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: -0.5px;
			line-height: 100%;
			margin-block-end: 0.5rem;
			text-align: end;
			@media screen and (max-width: $sm) {
				text-align: start;
			}
		}
	}

	&__date {
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 180%;
		opacity: 0.5;
		@media screen and (max-width: $sm) {
			text-align: start;
		}
	}

	&__additional {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__message {
		flex-grow: 2;
		min-inline-size: 100%;
		color: $black;
		@media screen and (max-width: $md) {
			font-size: 16px;
			line-height: 200%;
			margin-block-end: 14px;
		}
	}

	&__verified {
		aspect-ratio: 0;
		inline-size: 4.0625rem;
	}
}

.reviews-slider {
	&__pagination {
		position: relative;
	}
	&__button-prev {
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: -6.4rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-start: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}
	&__button-next {
		position: absolute;
		inset-block-start: 50%;
		inset-inline-end: -6.4rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-end: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}
	&__navigation {
		margin-block-start: 1.875rem;
	}
}
