@import "../../assets/styles/variables";

//.thrive-slider {
//	 .swiper-pagination {
//			.swiper-pagination-bullet {
//				background: hsl(0, 0%, 88%);
//				block-size: 0.125rem;
//				border-radius: 0;
//				inline-size: 3.75rem;
//				margin-block: 0 !important;
//				margin-inline: 0.4375rem !important;
//				opacity: 1;
//				@media screen and (max-width: $md) {
//					block-size: 0.375rem;
//					border-radius: 50%;
//					inline-size: 0.375rem;
//					margin-inline: 0.1875rem !important;
//				}
//
//				&:first-child {
//					margin-inline-start: 0 !important;
//				}
//				&.swiper-pagination-bullet-active {
//					background: $primary-color;
//					@media screen and (max-width: $md) {
//						background-color: $black;
//					}
//				}
//		}
//	}
//}

.quote-slider,
.reviews-slider {
	position: relative;
	display: block;
	min-block-size: 100%;

	@media screen and (max-width: $md) {
		position: static;
		flex-direction: column;
		inline-size: 100%;
		max-inline-size: 500px;
		min-block-size: unset;
	}
	@media screen and (max-width: $sm) {
		position: relative;
	}
	&__wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		block-size: auto;
		box-shadow: 0 0 80px 0 rgb(0 0 0 / 10%);
		max-inline-size: 100%;
		&::before {
			position: absolute;
			background: hsl(0, 0%, 88%);
			block-size: 100%;
			content: "";
			filter: blur(200px);
			inset-inline: 0;
		}
		@media screen and (max-width: $md) {
			overflow: visible !important;
		}
		@media screen and (max-width: $sm) {
			overflow: hidden !important;
		}
	}

	& .slider-navigation {
		@media screen and (max-width: $md) {
			position: absolute;
			margin: 0;
			column-gap: 1.25rem;
			inline-size: auto;
			inset-block-start: -0.625rem;
			inset-inline-end: 1.875rem;
		}
		@media screen and (max-width: $sm) {
			position: static;
			column-gap: 0;
			margin-top: 1.875rem;
		}
	}
	&__button-prev {
		position: absolute;
		z-index: 2;
		inset-block-start: 50%;
		inset-inline-start: -6.2rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-start: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}
	&__button-next {
		position: absolute;
		z-index: 2;
		inset-block-start: 50%;
		inset-inline-end: -6.2rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-end: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}

	& .swiper-pagination {
		z-index: 0;
		display: none;
		@media screen and (max-width: $md) {
			position: static;
			display: none;
			margin-block: 0;
			margin-block-start: 0;
			margin-inline: 2.3125rem;
			transform: none !important;
		}
		@media screen and (max-width: $sm) {
			display: flex;
		}
		.swiper-pagination-bullet {
			background: hsl(0, 0%, 88%);
			block-size: 0.125rem;
			border-radius: 0;
			inline-size: 3.75rem;
			margin-block: 0 !important;
			margin-inline: 0.4375rem !important;
			opacity: 1;
			@media screen and (max-width: $md) {
				block-size: 0.375rem;
				border-radius: 50%;
				inline-size: 0.375rem;
				margin-inline: 0.1875rem !important;
			}

			&:first-child {
				margin-inline-start: 0 !important;
			}
			&.swiper-pagination-bullet-active {
				background: $primary-color;
				@media screen and (max-width: $md) {
					background-color: $black;
				}
			}
		}

		@media screen and (max-width: $md) {
			max-inline-size: fit-content;
		}
	}
	&__slide {
		position: relative;
		flex: 0 0 49%;
		background-color: $white;
		border-radius: $border-radius;
		min-block-size: 23.6875rem;

		@media screen and (max-width: $md) {
			flex: 0 0 100%;
			min-block-size: 20.6875rem;
		}
	}
}

.video-slider,
.articles-slider {
	position: relative;
	&__pagination {
		.swiper-pagination-bullet {
			background: hsl(0, 0%, 88%);
			block-size: 0.125rem;
			border-radius: 0;
			inline-size: 3.75rem;
			margin-block: 0 !important;
			margin-inline: 0.4375rem !important;
			opacity: 1;
			@media screen and (max-width: $md) {
				block-size: 0.375rem;
				border-radius: 50%;
				inline-size: 0.375rem;
				margin-inline: 0.1875rem !important;
			}

			&:first-child {
				margin-inline-start: 0 !important;
			}
			&.swiper-pagination-bullet-active {
				background: $primary-color;
				@media screen and (max-width: $md) {
					background-color: $black;
				}
			}
		}

		@media screen and (max-width: $md) {
			max-inline-size: fit-content;
		}
	}

	& .swiper-pagination.video-slider__pagination,
	.swiper-pagination.articles-slider__pagination {
		position: absolute;
		inset-block-end: -2.125rem;
		inset-inline-start: 0;
		margin-block-start: 0.8rem;
		text-align: start;
		@media screen and (max-width: $md) {
			position: static;
			margin-block: 0;
			margin-block-start: 0;
			margin-inline: 2.3125rem;
		}
	}

	&__wrapper {
		margin-block-end: 8.25rem;
		@media screen and (max-width: $md) {
			margin-block-end: 0;
		}
	}
	&__content {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		padding: 3.125rem;
		block-size: 100%;
		@media screen and (max-width: $md) {
			padding: 2rem;
		}
		@media screen and (max-width: $sm) {
			padding: 1.875rem;
		}
	}
	&__heading {
		max-inline-size: 38.25rem;
	}
	& .video-slider__heading {
		color: $white;
		margin-block-end: 0;
		text-align: initial;
		@media screen and (max-width: $lg) {
			font-size: 2.1rem;
		}
		@media screen and (max-width: $sm) {
			font-size: 1.375rem;
			letter-spacing: -0.03125rem;
			line-height: 120%;
		}
	}
	&__preview {
		position: absolute;
		block-size: 100%;
		border-radius: $border-radius;
		inline-size: 100%;
		object-fit: cover;
	}
	&__slide {
		position: relative;
		flex: 0 0 auto;
		border-radius: $border-radius;
		cursor: pointer;
		min-block-size: 30rem;
		@media screen and (max-width: $lg) {
			min-block-size: 25rem;
		}
		@media screen and (max-width: $sm) {
			min-block-size: 15.625rem;
		}

		&:hover {
			& .play {
				background-color: $primary-color;
				color: $white;
			}
		}

		&::after {
			position: absolute;
			background-image: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.56) 100%
			);
			border-radius: $border-radius;
			content: "";
			inset: 0;
			pointer-events: none;
		}
	}
	&__duration {
		position: absolute;
		inset-block-start: 3.125rem;
		inset-inline-end: 3.125rem;
		@media screen and (max-width: $md) {
			inset-block-start: 2rem;
			inset-inline-end: 2rem;
		}
		@media screen and (max-width: $sm) {
			inset-block-start: 1.875rem;
			inset-inline-end: 1.875rem;
		}
	}
	&__tag {
		font-size: 1rem;
		letter-spacing: -0.5px;
		margin-block: auto 1.5625rem;
		@media screen and (max-width: $sm) {
			margin-block: auto 0.9375rem;
		}
	}
	&__button-prev {
		position: absolute;
		z-index: 2;
		inset-block-start: 50%;
		inset-inline-start: -6.2rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-start: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}
	&__button-next {
		position: absolute;
		z-index: 2;
		inset-block-start: 50%;
		inset-inline-end: -6.2rem;
		transform: translateY(-50%);
		@media screen and (max-width: $xl) {
			inset-inline-end: -0.9375rem;
		}
		@media screen and (max-width: $md) {
			position: static;
			display: inline-block;
			transform: none;
		}
	}
}

.slider-navigation {
	inline-size: 100%;
	&--margin-bottom {
		margin-block-end: 3.75rem;
	}
	@media screen and (max-width: $md) {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-block-start: 2.5rem;
	}
}
