.mobile-menu {
	position: fixed;
	z-index: 50;
	display: flex;
	flex-flow: column nowrap;
	background-color: $white;
	box-shadow: $card-shadow;
	inline-size: 16.125rem;
	inset-block-start: 0;
	inset-inline-end: -100%;
	max-block-size: 100vh;
	overflow-y: scroll;
	padding-block: 88px 40px;
	padding-inline: 40px;
	transition: 0.25s ease-in-out;
	&--opened {
		inset-inline-end: 0;
		inset-block-end: 0;
	}

	&__link {
		color: hsl(0, 0%, 51%);
		cursor: pointer;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 21px;
		margin-block-end: 25px;
		&:last-child {
			margin-block-end: 0;
		}
	}

	&__button-list {
		margin-block-end: 40px;
	}

	&__button {
		color: hsl(0, 0%, 20%);
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 600;
		letter-spacing: -0.5px;
		line-height: 100%;
		margin-block-end: 1.5625rem;
		&:last-child {
			margin-block-end: 0;
		}
	}

	&__dropdown {
		display: none;
		margin-block: 1.25rem 0.625rem;
		&--opened {
			display: block;
		}
	}
}
