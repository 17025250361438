.tabs {
	overflow: hidden;
	padding-block-end: 5rem;
	@media screen and (max-width: $sm) {
		padding-inline: 0;
	}

	&__heading{
		margin-block: 0 3.6875rem;
		margin-inline: auto;
		max-inline-size: 48rem;
		text-align: center;
		& .color-orange{
			display: block;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__nav-btn {
		background: $white;
		border-radius: 0.625rem;
		box-shadow: none;
		color: $black;
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: -0.5px;
		line-height: 120%;
		min-block-size: 4rem;
		padding-block: 0;
		padding-inline: 1.5625rem;
		text-align: center;
		text-transform: uppercase;
		transition: all 0.4s ease;
		white-space: nowrap;

		&.js--active {
			background: $orange;
			color: $white;
			transition: all 0.4s ease;
			& svg {
				fill: $white;
				& g {
					opacity: 1;
				}
			}
		}

		& svg {
			margin-block: 0;
			margin-inline: 0 1rem;
		}

		&:hover {
			background: $orange;
			color: $white;
			transition: all 0.4s ease;

			& svg {
				fill: $white;
				& g {
					opacity: 1;
				}
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background-color: hsl(0, 0%, 100%);
		//box-shadow: $card-shadow;
		column-gap: 1rem;
		inline-size: max-content;
		margin-block: 0 3.125rem;
		max-inline-size: calc(100% + 2.5rem);
		padding-inline: 2rem;
		&.swiper {
			overflow: visible;
			border-radius: $border-radius;
			//width: 100%;
		}
		& .swiper-wrapper {
			background-color: hsl(0, 0%, 96%);
			inline-size: max-content;
		}
	}

	&__nav-wrapper {
		display: flex;
		background-color: $white;
		box-shadow: $card-shadow !important;
		column-gap: 0.625rem;
		& .swiper-slide {
			inline-size: max-content;
		}
	}

	&__yelp-link {
		color: hsl(0, 0%, 20%, 0.6);
		font-size: 0.75rem;
		font-weight: 600;
		letter-spacing: -0.03125rem;
		line-height: 100%;
		margin-block-start: -0.625rem;
	}

	&__yelp-svg {
		margin-block: 0;
		margin-inline: 0.1875rem 0;
	}
}

.tabs-inner {
	display: flex;
	align-items: flex-start;
	inline-size: 100%;
	margin-block: 0 2.25rem;
	margin-inline: 0;
}

.tab {
	display: none;
	flex-direction: column;
	inline-size: 100%;
	row-gap: 1.25rem;

	&.js--active {
		display: flex;
	}
}

.tab-card {
	display: flex;
	padding: 2.5rem;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: $card-shadow;
	color: $light-black;
	column-gap: 2.5rem;
	@media screen and (max-width: $md) {
		flex-flow: column;
		row-gap: 1.5625rem;
	}
	@media screen and (max-width: $sm) {
		padding: 1.25rem;
	}

	&__image {
		aspect-ratio: 1.5;
		border-radius: $border-radius;
		inline-size: 11.125rem;
		object-fit: cover;
		@media screen and (max-width: $md) {
			aspect-ratio: 2;
			inline-size: 100%;
		}
		@media screen and (max-width: $md) {
			aspect-ratio: 1.7;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: flex-start;
		justify-content: space-between;
		@media screen and (max-width: $md) {
			row-gap: 1.5625rem;
		}
	}

	&__contacts {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		background-color: $light-grey;
		border-radius: $border-radius;
		inline-size: 9.6875rem;
		padding-block: 1.875rem;
		padding-inline: 1.1875rem;
		@media screen and (max-width: $md) {
			inline-size: 100%;
		}
		@media screen and (max-width: $sm) {
			margin-block: -0.625rem 0;
			margin-inline: 0;
			padding-block: 1.3125rem;
			padding-inline: 5rem;
			row-gap: 0.625rem;
		}
	}

	&__phone {
		font-size: 1rem;
		font-weight: 600;
		letter-spacing: -0.03125rem;
		line-height: 1.5rem;
		margin-block-start: 0.9375rem;
		text-align: center;
		text-transform: uppercase;
		@media screen and (max-width: $sm){
			margin-block-start: 0;
		}
	}

	&__address {
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.03125rem;
		line-height: 1.125rem;
		margin-block-start: 1.4375rem;
		opacity: 0.5;
		text-align: center;
		text-transform: capitalize;
		@media screen and (max-width: $sm){
			margin-block-start: 0;
		}
	}

	&__name {
		font-size: 1.25rem;
		font-weight: 500;
		letter-spacing: -0.03125rem;
		line-height: 1.875rem;
		@media screen and (max-width: $md) {
			font-size: 1.125rem;
			margin-block-end: -0.625rem;
		}
	}

	&__description {
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.03125rem;
		line-height: 1.3125rem;
		opacity: 0.6;
		@media screen and (max-width: $md) {
			line-height: 1.625rem;
		}
	}

	&__reviews {
		display: flex;
		align-items: center;
		column-gap: 0.9375rem;
	}

	&__reviews-num {
		flex-shrink: 1;
		color: hsl(0, 0%, 20%);
		font-size: 0.75rem;
		font-weight: 400;
		letter-spacing: -0.03125rem;
		line-height: 1.125rem;
		opacity: 0.5;
		text-transform: capitalize;
	}

	&__stars {
		aspect-ratio: 5.62;
		background-color: $orange;
		inline-size: 11.25rem;
		mask-image: url("/src/assets/images/stars-big.svg");
		mask-repeat: no-repeat;
		mask-size: contain;
		@media screen and (max-width: 21.875rem) {
			inline-size: 9.375rem;
		}
	}

	&__link {
		cursor: pointer;
		&:hover{
			text-decoration: none;
		}
		@media screen and (max-width: $sm) {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.625rem;
			background-color: $orange;
			border-radius: $border-radius;
			color: $white;
			inline-size: 100%;
			min-block-size: 2.5rem;
			text-decoration: none;

			& .color-orange {
				color: $white;
				cursor: pointer;
			}
		}
	}
}

.yelp-arrow{
	display: none;
	@media screen and (max-width: $sm) {
		display: block;
	}
}
