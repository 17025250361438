.header {
	position: sticky;
	z-index: 50;
	display: flex;
	background-color: $white;
	border-block-end: 1px solid hsl(0, 0%, 88%);
	inset-block-start: 0;
	min-block-size: 5.625rem;
	@media screen and (max-width: $md) {
		min-block-size: 4.875rem;
	}

	&__main-logo {
		min-inline-size: 11.4375rem;
		@media screen and (max-width: $md) {
			inline-size: 9.625rem;
			min-inline-size: auto;
		}
	}

	&__main-logo-container {
		display: flex;
		align-items: center;
		@media screen and (max-width: $lg) {
			flex-grow: 1;
			order: 1;
		}
		@media screen and (max-width: $sm) {
			flex-grow: 0;
			order: 2;
		}
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__buttons-list {
		display: flex;
		align-items: center;
		column-gap: 4rem;
	}

	&__button-group {
		&--nav {
			@media screen and (max-width: $lg) {
				display: none;
			}
		}
		&--acc {
			@media screen and (max-width: $lg) {
				order: 2;
				margin-inline-end: 40px;
			}
			@media screen and (max-width: $sm) {
				display: none;
			}
		}
	}

	&__button {
		display: flex;
		align-items: center;
		color: $light-black;
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: -0.03125rem;
		line-height: 150%;
		transition: 0.2s ease;
		& svg {
			margin-inline-start: 0.625rem;
		}
		&:hover {
			color: $primary-color;
			& .header__button-icon {
				& path {
					fill: $primary-color;
					transition: 0.2s ease;
				}
			}
		}

		& .dropdown-arrow {
			margin-inline-start: 0.3125rem;
		}

		&--account {
			text-decoration: underline;
			@media screen and (max-width: $lg) {
				display: none;
			}
		}
	}

	&__apply-button {
		padding-block: 0;
		padding-inline: 1.0625rem;
	}

	&__button-icon {
		margin-block: 0;
		margin-inline: 0 0.3125rem;
	}

	&__mobile-button {
		position: relative;
		display: none;
		align-items: center;
		justify-content: center;
		block-size: 2.375rem;
		border-radius: 50%;
		inline-size: 2.375rem;
		@media screen and (max-width: $lg) {
			display: flex;
		}

		&--user {
			background-color: $light-black;
			@media screen and (max-width: $lg) {
				order: 3;
				margin-inline-end: 1.25rem;
			}
			@media screen and (max-width: $sm) {
				order: 1;
			}
		}

		&--burger {
			z-index: 60;
			background-color: $primary-color;
			@media screen and (max-width: $lg) {
				order: 4;
			}
			& span {
				position: absolute;
				background-color: $white;
				block-size: 2px;
				inline-size: 58%;
				inset-block-start: 50%;
				inset-inline-start: 50%;
				transition: .25s ease-in-out;
			}
			& span:nth-child(1) {
				transform: translate(-50%, calc(-50% + 4px));
			}
			& span:nth-child(2) {
				transform: translate(-50%, calc(-50% - 4px));
			}
			&.open {
				& span:nth-child(1) {
					transform: rotate(45deg) translate(calc(-50% + 3px), calc(-50% + 8px));
				}
				& span:nth-child(2) {
					transform: rotate(-45deg) translate(calc(-50% + 4px), calc(-50% - 7px));
				}
			}

		}

	}

}
