.menu {
	position: fixed;
	z-index: 5;
	align-items: stretch;
	justify-content: space-between;
	background-color: $white;
	box-shadow: $card-shadow;
	inline-size: 100%;
	inset-block-start: -100vh;
	inset-inline-start: 0;
	block-size: 100vh;
	overflow-y: scroll;
	padding-block: 11.875rem 0;
	padding-inline: 0;
	transition: inset-block-start 0.5s ease;
	background-color: $white;

	@media screen and (max-width: $md) {
		padding-block: 7.125rem 0;
	}

	&__container {
		z-index: 1;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		block-size: max-content;
		column-gap: 6.25rem;
		padding-block-end: 11.875rem;
		background-color: $white;
		@media screen and (max-width: $md) {
			padding-block-end: 6.875rem;
		}
	}

	&--opened {
		display: flex;
		inset-block-start: 0;
	}

	&__links {
		inline-size: 49%;
	}

	&__cards {
		display: none;
		flex-flow: wrap;
		flex-shrink: 0;
		align-items: stretch;
		animation: hide 1s ease;
		gap: 1.25rem;
		inline-size: 49%;
		min-inline-size: 36.875rem;

		&.js--active {
			display: flex;
			animation: show 1s ease;
			opacity: 1;
		}
	}

	&__heading-container {
		display: flex;
		align-items: center;
		column-gap: 1.875rem;
		margin-block: 0 1.875rem;
		margin-inline: 0;
		@media screen and (max-width: $md) {
			margin-block-end: 2.1875rem;
		}
	}

	&__heading {
		flex-shrink: 1;
		margin: 0;
	}

	&__links-container {
		column-count: 2;
		column-gap: 3.125rem;
	}

	&__link-button {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: $light-black;
		font-size: 1rem;
		font-weight: 500;
		inline-size: 100%;
		letter-spacing: -0.5px;
		line-height: 100%;
		min-block-size: 4.25rem;
		text-align: start;
		&:hover {
			color: $primary-color;
			& .menu__link-button-number {
				opacity: 1;
			}
		}
		&.js--active {
			color: $primary-color;

			& .menu__link-button-number {
				opacity: 1;
			}
		}
		@media screen and (max-width: $md) {
			min-block-size: 4.125rem;
		}
	}

	&__link-button-number {
		flex-shrink: 0;
		block-size: 100%;
		inline-size: 2rem;
		opacity: 0.3;
		transition: opacity 0.3s ease, color 0.3s ease;
	}

	&__link-button-text {
		flex-grow: 1;
		transition: opacity 0.3s ease, color 0.3s ease;
		line-height: 130%;
	}

	&__link-button-divider {
		position: absolute;
		background-color: $light-black;
		block-size: 1px;
		inline-size: calc(100% - 2rem);
		inset-block-end: 0;
		inset-inline-end: 0;
		opacity: 0.2;

		&--hidden {
			display: none;
		}
	}

	&__card {
		flex-shrink: 1;
		padding: 30px;
	}

	&__card-title {
		z-index: 5;
		display: flex;
		flex-grow: 1;
		align-items: flex-end;
		font-size: 0.75rem;
		font-weight: 500;
		letter-spacing: -0.5px;
		line-height: 100%;
		opacity: 0.5;

		&::before {
			content: "•";
			margin-block: 0;
			margin-inline: 0 5px;
		}
	}

	&__video-card {
		position: relative;
		overflow: hidden;
		border-radius: $border-radius;
		color: $white;
		inline-size: 100%;
		min-block-size: 14.875rem;
		cursor: pointer;

		& .menu__video-card-mask {
			transition: opacity 0.3s ease;
		}

		@media screen and (max-width: $md) {
			min-block-size: 15.625rem;
		}
		&:hover {
			& .menu__video-card-mask {
				opacity: 0.75;
			}
		}
	}

	&__video-card-mask {
		position: absolute;
		z-index: 0;
		background: linear-gradient(
			180deg,
			rgba(7, 190, 203, 0) 0%,
			rgba(7, 190, 203, 0.92) 100%
		);
		block-size: 100%;
		inline-size: 100%;
		inset-block-start: 0;
		inset-inline-start: 0;
	}

	&__video-card-image {
		position: absolute;
		z-index: -1;
		block-size: 100%;
		inline-size: 100%;
		inset-block-start: 0;
		inset-inline-start: 0;
		object-fit: cover;
	}

	&__video-card-time {
		position: absolute;
		z-index: 1;
		padding: 0.7rem;
		background-color: $white;
		border-radius: $border-radius;
		color: $light-black;
		font-size: 14px;
		font-weight: 500;
		inset-inline-end: 1.875rem;
		letter-spacing: -0.03125rem;
		line-height: 100%;
		min-block-size: 2rem;
		min-inline-size: 3.125rem;
		text-align: center;
	}

	&__video-card-links {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		padding: 30px;
		block-size: 100%;
		inline-size: 100%;
		inset-block-start: 0;
		inset-inline-start: 0;
	}

	&__video-card-play {
		z-index: 5;
		display: flex;
		flex-flow: row;
		align-items: center;
		justify-content: center;
		background-color: $white;
		block-size: 40px;
		border-radius: 50%;
		inline-size: 40px;
	}

	&__card-heading {
		@media screen and (max-width: $md) {
			font-size: 22px;
			line-height: 120%;
			min-block-size: 99px;
		}
	}

	&__video-card-heading {
		font-weight: 500;
		line-height: 100%;
		margin-block-start: 1.5rem;
		max-inline-size: 25rem;
		@media screen and (max-width: $md) {
		}
	}

	&__info-card {
		display: flex;
		flex-flow: column;
		background: hsl(0, 0%, 100%);
		border-radius: $border-radius;;
		box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
		inline-size: calc(50% - 0.625rem);
		row-gap: 1.25rem;
	}

	&__info-card-heading {
		color: hsl(0, 0%, 14%);
		font-size: 1.125rem;
		font-weight: 600;
		letter-spacing: -0.5px;
		line-height: 140%;
	}

	&__close-button {
		flex-shrink: 0;
	}
}

@media screen and (max-width: $xl) {
	.menu {
		&__container {
			flex-flow: column;
		}
		&__links {
			inline-size: 100%;
		}
		&__cards {
			inline-size: 100%;
		}
	}
}

@media screen and (max-width: $md) {
	.menu {
		&__links-container {
			columns: 1;
		}
		&__cards {
			min-inline-size: unset;
		}
	}
}

@media screen and (max-width: $sm) {
	.menu {
		//padding-block-start: 40px;
		&__info-card {
			inline-size: 100%;
		}
		&__heading-container {
			flex-flow: column;
			align-items: center;
			row-gap: 20px;
		}
		&__container {
			row-gap: 2.1875rem;
		}
		&__link-button-divider {
			inline-size: 100%;
		}
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
