@import "~/src/assets/styles/variables.scss";

.member {
	position: relative;
	@media screen and (max-width: $md) {
		overflow-x: clip;
		overflow-y: visible;
	}
	&__content {
		position: relative;
		z-index: 2;
		inline-size: 100%;
		max-inline-size: 19.25rem;
		@media screen and (max-width: $md) {
			max-inline-size: 19.25rem;
		}
		@media screen and (max-width: $sm) {
			max-inline-size: none;
		}
	}
	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media screen and (max-width: $sm) {
			flex-direction: column;
		}
	}
	& .heading.member__heading {
		margin-block-end: 1.5625rem;
		text-align: initial;
		@media screen and (max-width: $sm) {
			text-align: center;
		}
	}
	&__subheading {
		color: $black;
		font-size: 1rem;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 200%;
		margin-block: 1.5625rem 2.5rem;
		margin-inline: 0;
		@media screen and (max-width: $md) {
			font-size: 0.875rem;
			margin-block: 1.5625rem 2.1875rem;
		}
		@media screen and (max-width: $sm) {
			color: $black;
			line-height: 180%;
			margin-block: 1.25rem 0;
			margin-inline: 0;
			opacity: 0.6;
			text-align: center;
		}
	}
	&__photo {
		max-width: 37rem;
		width: 100%;
		position: absolute;
		inset-block-end: -1px;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		max-height: 33.4375rem;
		object-fit: contain;

		@media screen and (max-width: $md) {
			block-size: 30.25rem;
		}
		@media screen and (max-width: $sm) {
			block-size: min-content;
			transform: translateX(0);
			position: static;
			display: block;
			max-inline-size: 100%;
			inline-size: 21.5625rem;
			margin-inline: auto;
		}
	}

	.button.member__button-desktop {
		@media screen and (max-width: $sm) {
			display: none;
			inline-size: 100%;
		}
	}

	.button.member__button-mobile {
		display: none;
		@media screen and (max-width: $sm) {
			display: flex;
			justify-content: center;
			inline-size: 100%;
		}
	}

	&.two-members {
		& .member__container {
			max-inline-size: 76.875rem;
			& .member__photo {
				max-width: 42rem;
				@media screen and (max-width: $sm) {
					position: static;
					display: block;
					block-size: min-content;
					inline-size: 100%;
					max-inline-size: 29.5625rem;
					margin-inline: auto;
					margin-bottom: -10px;
				}
			}
			& .member-info {
				@media screen and (max-width: $lg) {
					font-size: 1.125rem;
					padding: 1.5625rem;
					margin-block-start: 3.9375rem;
					margin-inline-start: 0.625rem;
				}
				@media screen and (max-width: $sm) {
					padding: 35px;
					inline-size: 100%;
					margin-block-end: 1.25rem;
					margin-block-start: 0;
					margin-inline-start: 0;
					max-inline-size: none;
				}
				&__social-heading {
					@media screen and (max-width: $lg) {
						margin-block: 1rem 0.8rem;
					}
					@media screen and (max-width: $sm) {
						margin-block: 1.875rem 1.25rem;
					}
				}
				& .social {
					&__link {
						@media screen and (max-width: $lg) {
							block-size: 2.25rem;
							inline-size: 2.25rem;
							& svg {
								inline-size: 0.9375rem;
							}
						}
						@media screen and (max-width: $sm) {
							block-size: 3.125rem;
							inline-size: 3.125rem;
							& svg {
								inline-size: 1.125rem;
							}
						}
					}
				}
			}
		}
	}
}

.member-info {
	position: relative;
	z-index: 3;
	padding: 2.1875rem;
	background: $white;
	border-radius: $border-radius;
	box-shadow: $card-shadow;
	margin-inline-start: auto;
	max-inline-size: fit-content;
	@media screen and (max-width: $md) {
		padding: 1.5625rem;
		margin-block-start: 3.9375rem;
		margin-inline-start: 0.625rem;
	}
	@media screen and (max-width: $sm) {
		padding: 35px;
		inline-size: 100%;
		margin-block-end: 1.25rem;
		margin-block-start: 0;
		margin-inline-start: 0;
		max-inline-size: none;
	}
	&__name {
		color: $black;
		font-size: 1.125rem;
		font-weight: 600;
		letter-spacing: -0.5px;
		line-height: 140%;
		margin-block-end: 0.4375rem;
		@media screen and (max-width: $md) {
			font-size: 1rem;
		}
		@media screen and (max-width: $sm) {
		}
	}
	&__role {
		color: hsl(0, 0%, 51%);
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 160%;
		margin-block-end: 1.25rem;
		@media screen and (max-width: $md) {
			font-size: 0.875rem;
			margin-block-end: 0.625rem;
		}
	}
	&__social-heading {
		color: $black;
		font-size: 0.875rem;
		font-weight: 500;
		letter-spacing: -0.5px;
		line-height: 110%;
		margin-block: 2.1875rem 1.25rem;
		margin-inline: 0;
		@media screen and (max-width: $md) {
			margin-block: 1rem 0.8rem;
		}
		@media screen and (max-width: $sm) {
			margin-block: 1.875rem 1.25rem;
		}

	}
}

.social {
	display: flex;
	align-items: center;
	gap: 0.9375rem;
	@media screen and (max-width: $md) {
		gap: 0.625rem;
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;
		block-size: 3.125rem;
		border-radius: 50%;
		box-shadow: 0 14px 64px rgba(70, 161, 209, 0.42);
		color: $primary-color;
		inline-size: 3.125rem;
		transition: background-color 300ms ease, color 300ms ease;
		@media screen and (max-width: $md) {
			block-size: 2.25rem;
			inline-size: 2.25rem;
			& svg {
				inline-size: 0.9375rem;
			}
		}
		@media screen and (max-width: $sm) {
			block-size: 3.125rem;
			inline-size: 3.125rem;
			& svg {
				inline-size: 1.125rem;
			}
		}
		& .svg-white {
			fill: $white;
		}
		&:hover {
			background-color: $primary-color;
			color: $white;
			& .svg-primary-color {
				color: $white;
			}
			& .svg-white {
				fill: $primary-color;
			}
		}
	}
}
