.experience {
	position: relative;
	position: relative;
	overflow: hidden;
	background-color: hsl(0, 0%, 14%);
	background-image: url("~/src/assets/images/experience-logo.svg");
	background-position: top right;
	background-repeat: no-repeat;
	background-size: contain;
	color: $white;
	@media screen and (max-width: $sm) {
		padding-block: 35px 30px;
		padding-inline: 16px;
	}

	&__info {
		z-index: 2;
		display: flex;
		align-items: center;
		column-gap: 1.25rem;
		margin-block: 0 2.5rem;
		margin-inline: 0;
	}

	&__userpic {
		display: none;
		flex-shrink: 0;
		aspect-ratio: 1;
		block-size: 3.75rem;
		border-radius: 50%;
		inline-size: 3.75rem;
		object-fit: cover;
		@media screen and (max-width: $xmd) {
			display: block;
			flex-flow: column;
		}
	}

	&__background {
		position: absolute;
		inline-size: 33rem;
		inset-block-end: 0;
		inset-inline-end: 50%;
		max-block-size: 29rem;
		object-fit: contain;
		transform: translate(1.875rem, 0);
		@media screen and (max-width: 56.25rem) {
			display: none;
		}
	}

	&__name {
		color: $white;
	}

	& .paragraph {
		color: $grey;
	}

	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@media screen and (max-width: $xmd) {
			display: block;
		}
	}

	&__name {
		color: $white;
		margin-block: 0 0.4375rem;
		margin-inline: 0;
		@media screen and (max-width: $xmd) {
			inline-size: 100%;
		}
	}

	&__competention{
		margin: 0;
		inline-size: 90%;
		line-height: 120%;
		@media screen and (max-width: $xmd) {
			inline-size: 100%;
		}
	}

	&__data {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: 0;
		color: $white;
		inset-block-start: 0;
		inset-inline-end: 0;
		max-inline-size: 30.4rem;
	}

	&__stars {
		display: flex;
		align-items: center;
		column-gap: 0.625rem;
		margin-block: 0 0.75rem;
		margin-inline: 0;
	}
	&__stars-icon {
		margin: 0;
		background-color: hsl(43, 100%, 50%);
		block-size: 1.25rem;
		inline-size: 7rem;
		mask-image: url("/src/assets/images/stars-experience.svg");
		transform: translate(0, -2px);
	}

	&__quote {
		color: $white;
		margin-block: 0 2.25rem;
		margin-inline: 0;
		text-align: start;
		@media screen and (max-width: $md) {
			margin-block-end: 31px;
		}
	}

	&__stars-description {
		color: $grey;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		letter-spacing: -0.03125rem;
		line-height: 100%;
		margin-block: 0 2.2rem;
		margin-inline: 0;
		@media screen and (max-width: $sm) {
			line-height: 19px;
			margin-block-end: 31px;
		}
	}

	&__stars-description-link {
		color: $primary-color;
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}


	&__main-link-img {
		max-inline-size: 11.4375rem;
	}

	&.two-members {
		& .experience__background {
			inset-inline-end: 45%;
			@media screen and (max-width: 80rem) {
				inset-inline-end: 40%;
			}
		}
		& .experience__quote {
			max-inline-size: 25rem;
			font-size: 1.9rem;
		}
		& .experience__data {
			max-inline-size: 25rem;
		}
	}
}
