.hero{
	position: relative;
	overflow: hidden;
	background: linear-gradient(102.89deg, rgba(4, 163, 174, 0.03) 0%, rgba(8, 190, 203, 0.04) 38.85%, rgba(54, 167, 255, 0.02) 71.67%, rgba(2, 110, 194, 0.05) 100%);
	padding-block: 5rem 5.5rem;
	&.section{
		border-block-end: none;
	}
	@media screen and (max-width: $md){
		padding-block: 2.5rem;
		padding-inline: 0;
	}
	@media screen and (max-width: 54.25rem) {
		min-block-size: 33.5625rem;
		padding-block-start: 5.125rem;
	}
	@media screen and (max-width: 42.5rem) {
		padding-block-start: 2.375rem;
	}

	&__background {
		position: absolute;
		z-index: -1;
		inline-size: 640px;
		inset-block-end: 0;
		inset-inline-start: 50%;
		max-block-size: 36.8125rem;
		object-fit: contain;
		transform: translate(-9.3125rem, 8.3125rem);
		@media screen and (max-width: 71.875rem) {
			max-block-size: 34.8125rem;
			transform: translate(-50%, 8.3125rem);
		}
		@media screen and (max-width: 54.25rem) {
			inline-size: 100%;
			inset-inline: unset;
			max-inline-size: 36.5rem;
			transform: translate(4.125rem, 4.6875rem);
		}
		@media screen and (max-width: 42.5rem) {
			position: relative;
			order: 2;
			grid-area: background;
			inset-block-end: unset;
			inset-inline: unset;
			margin-block: -2.125rem;
			margin-inline: auto;
			max-inline-size: 23.625rem;
			transform: none;
		}
		@media screen and (max-width: $sm) {
			margin-block: -2.375rem -0.625rem;
		}
	}

	&__container {
		position: relative;
		display: flex;
		flex-flow: column;
		@media screen and (max-width: 42.5rem) {
			padding-inline: 0;
		}
		@media screen and (max-width: 23.375rem) {
			padding-inline: 1.25rem;
		}
	}

	&__heading-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		grid-area: title;
		max-inline-size: 37.5rem;
		@media screen and (max-width: 68.75rem) {
			justify-content: flex-start;
			padding-inline: 1.125rem;
		}
		@media screen and (max-width: 42.5rem) {
			order: 1;
			margin-block: 0;
			margin-inline: auto;
		}
		@media screen and (max-width: $sm) {
			max-inline-size: 100%;
		}
	}

	&__heading {
		margin-block-end:1.2rem;
		@media screen and (max-width: 71.875rem) {
			font-size: 3rem;
			max-inline-size: 12ch;
		}
		@media screen and (max-width: $lg) {
			font-size: 3rem;
		}
		@media screen and (max-width: 42.5rem) {
			text-align: center;
		}
		@media screen and (max-width: 54.25rem) {
			line-height: 114%;
			margin-block-end: 2.0625rem;
			& .color-primary{
				display: block;
			}
		}
		@media screen and (max-width: $sm) {
			margin-block-end: 1.3rem;
		}
	}

	&__subheading {
		margin-block-end: 2.5rem;
		max-inline-size: 25rem;
		@media screen and (max-width: 71.875rem) {
			font-size: 1rem;
			max-inline-size: 30ch;
		}
		@media screen and (max-width: 42.5rem) {
			font-size: 14px;
			margin-inline: auto;
			text-align: center;
		}
	}

	&__awards {
		grid-area: awards;
	}

	&__button {
		grid-area: button;
	}

	&__apply-button {
		@media screen and (max-width: 71.875rem) {
			margin-inline-start: 1.25rem;
		}
		@media screen and (max-width: 42.5rem) {
			position: relative;
			display: flex;
			justify-content: center;
			order: 4;
			inline-size: 100%;
			margin-inline: auto;
			max-inline-size: 21.5625rem;
		}
		@media screen and (max-width: $md) {
			margin-block: 0;
		}
	}

	&.two-members {
		& .hero__heading-container {
			max-inline-size: 23rem;
			@media screen and (max-width: 71.875rem) {
				font-size: 3rem;
				max-inline-size: 37.5rem;
			}
			@media screen and (max-width: 56.25rem) {
				max-inline-size: 100%;
				order: 1;
				margin-block: 0;
				margin-inline: auto;
			}
			@media screen and (max-width: $sm) {
				max-inline-size: 100%;
			}
		}
		& .hero__background {
			inline-size: 52rem;
			max-block-size: 52rem;
			inset-inline-start: 33%;
			@media screen and (max-width: 71.875rem) {
				transform: translate(-23%, 8.3125rem);
				max-block-size: 34.8125rem;
			}
			@media screen and (max-width: $lg) {
				transform: translate(-28%, 8.3125rem);
				inline-size: 100%;
			}
			@media screen and (max-width: 56.25rem) {
				position: relative;
				order: 2;
				grid-area: background;
				inset-block-end: unset;
				inset-inline: unset;
				margin-block: -2.125rem;
				margin-inline: auto;
				max-inline-size: 23.625rem;
				transform: none;
			}
			@media screen and (max-width: $sm) {
				margin-block: -2.375rem -0.625rem;
			}
		}
		& .hero__heading {
			font-size: 4rem;
			@media screen and (max-width: 71.875rem) {
				font-size: 3rem;
				max-inline-size: 12ch;
			}
			@media screen and (max-width: $lg) {
				font-size: 3rem;
			}
			@media screen and (max-width: 56.25rem) {
				text-align: center;
				max-inline-size: 20ch;
			}
			@media screen and (max-width: 54.25rem) {
				line-height: 114%;
				margin-block-end: 2.0625rem;
				& .color-primary{
					display: block;
				}
			}
			@media screen and (max-width: $sm) {
				margin-block-end: 1.3rem;
			}
		}
		& .hero__subheading {
			@media screen and (max-width: 56.25rem) {
				font-size: 0.875rem;
				margin-inline: auto;
				text-align: center;
				max-inline-size: 50ch;
			}
		}
		& .hero__apply-button {
			@media screen and (max-width: 56.25rem) {
				position: relative;
				display: flex;
				justify-content: center;
				order: 4;
				inline-size: 100%;
				margin-inline: auto;
				max-inline-size: 21.5625rem;
			}
			@media screen and (max-width: $md) {
				margin-block: 0;
			}
		}
	}
}
