.buyside {
  position: relative;
  min-height: 27.75rem;
  padding-block: 3.75rem;
  display: flex;
  background: linear-gradient(102.89deg, rgba(4, 163, 174, 0.03) 0%, rgba(8, 190, 203, 0.04) 38.85%, rgba(54, 167, 255, 0.02) 71.67%, rgba(2, 110, 194, 0.05) 100%);
  @media screen and (max-width: $sm) {
    min-height: unset;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__form {
    min-height: 13rem;
    width: 30.5rem;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    z-index: 1;
    @media screen and (max-width: $sm) {
      width: 100%
    }
  }
  &__form-heading {
    text-align: left;
    flex-grow: 0;
    margin: 0 0 1.25rem 0;
    z-index: 1;
    @media screen and (max-width: $md) {
      width: 80%
    }
    @media screen and (max-width: 39.375rem) {
      width: 50%;
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      text-align: center
    }
  }
  &__form-subheading {
    text-align: left;
    flex-grow: 1;
    max-width: 27rem;
    margin: 0 0 1.875rem 0;
    z-index: 1;
    @media screen and (max-width: $md) {
      width: 50%
    }
    @media screen and (max-width: $sm) {
      width: 100%;
      margin: 0 auto 1.875rem;
      text-align: center
    }
  }
  &__photo {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-block-size: 31.75rem;
    z-index: 0;
    @media screen and (max-width: $md) {
      transform: translateX(-10%)
    }
    @media screen and (max-width: $sm) {
      display: none;
    }
  }
  &__input-container {
    position: relative;
    flex-grow: 0;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 3.5rem;
    background-color: $white;
    border-radius: $border-radius;
    padding: 0.3125rem;
    column-gap: 0.625rem;
    z-index: 1;
  }
  &__input-inner {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: row-reverse;
    & .buyside_widget_input_div {
      overflow: auto;
      flex-grow: 1;
      overflow: visible; 
      padding-right: 0.5em; 
      & input {
        width: 100%;
      }
      & .buyside_widget_autocomplete-items { 
        border: none; 
        padding-inline: 0; 
        z-index: 99; 
        top: 100%; 
        left: 0; 
        right: 0; 
        background-color: white; 
        max-height: 35vh; 
        overflow-y: auto; 
        text-align: left;
        box-shadow: $card-shadow;
        & div {
          padding: 0.9375rem; 
          cursor: pointer; 
          background-color: #fff; 
          font-size: small; 
        }
        & .buyside_widget_autocomplete_item {
          border: none;
          outline: none;
          min-height: 3rem;
          border-radius: 0.3125rem;
          align-items: center;
          padding-inline: 0.9375rem;
        }
        & .buyside_widget_autocomplete-active {
          background-color: #f2f2f2 !important; 
          color: #000000; 
        }
      } 
    }
  }
  &__input {
    position: relative;
    border: none;
    height: 100%;
    flex-grow: 1;
    outline: none;
    padding: 0.9375rem;
    outline: 1px solid;
    outline-color: rgba(0,0,0,0);
  }
  &__submit-button {
    flex-shrink: 0;
    @media screen and (max-width: 30rem) {
      padding-inline: 1rem;
    }
  }

  &.two-members {
    & .buyside__container {
      & .buyside__form {
        & .buyside__photo {
          transform: translateX(-15%);
          @media screen and (max-width: $md) {
            transform: translateX(-30%)
          }
        }
      }
    }
  }
}
