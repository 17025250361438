//articles

.articles {
	overflow: hidden;
	position: relative;
	padding-block-end: 5rem;
	@media screen and (max-width: $sm) {
		padding-block: 5rem;
		padding-inline: 0;
	}

	&__heading-container {
		display: flex;
		align-items: center;
		column-gap: 1.25rem;
		margin-block: 0 5rem;
		margin-inline: 0;
		@media screen and (max-width: $md) {
			margin-bottom: 3.5625rem;
		}
		@media screen and (max-width: $sm) {
			text-align: center;
		}
	}

	&__heading {
		flex-grow: 1;
		margin: 0;
		text-align: start;
		@media screen and (max-width: $sm) {
			text-align: center;
		}
	}
}

//articles-slider

.articles-slider {
	overflow: visible !important;
	position: relative;

	&__nav {
		display: flex;
		column-gap: 1.25rem;
		&--on-top {
			@media screen and (max-width: $sm) {
				display: none;
			}
		}
		&--on-bottom {
			display: none;
			width: 100%;
			@media screen and (max-width: $sm) {
				display: flex;
			}
		}
	}

	&__pagination {
		position: relative !important;
		display: flex;
		bottom: unset !important;
	}

	&__container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		inline-size: 100%;
	}

	&__wrapper {
		display: flex;
		overflow: hidden;
		width: auto;
		background-color: hsl(0, 0%, 96%);
		box-shadow: $card-shadow;
		inline-size: min-content;
		&.swiper-wrapper {
			width: min-content;
			margin-block-end: 0;
		}
	}

	&__button {
		position: absolute;
		z-index: 2;
		box-shadow: $dark-blue-shadow;
		inset-block-start: 50%;

		&-next {
			inset-inline-end: -2.5rem;
			transform: translateY(-50%);
		}

		&-prev {
			inset-inline-start: -2.5rem;
			transform: translateY(-50%);
		}
	}
}

//articles-slide

.articles-slide {
	display: flex;
	flex-direction: column;
	padding: 1.875rem;
	background-color: $white;
	border-radius: $border-radius;
	inline-size: 24.125rem;
	min-block-size: 12.5rem;
	@media screen and (max-width: $sm) {
		padding: 1.25rem 1.25rem 2.5rem;
	}

	&__image {
		aspect-ratio: 1.22;
		border-radius: $border-radius;
		border-radius: $border-radius;
		margin-block: 0 1.875rem;
		margin-inline: 0;
		object-fit: cover;
		@media screen and (max-width: $sm) {
			aspect-ratio: 1.15;
		}
	}

	&__heading {
		margin-block: 0 0.625rem;
		margin-inline: 0;
	}

	&__description {
		font-size: 0.875rem;
		margin-block: 0 1.5625rem;
		margin-inline: 0;
		@media screen and (max-width: $sm) {
			line-height: 200%;
		}
	}

	&--last {
		margin-inline-end: 0 !important;
	}
}
