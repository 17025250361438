// to use variables in component, module or page scss file you need to import this file using
// @import '~/src/assets/styles/variables';

//colors
$primary-color: hsl(184, 93%, 41%);
$light-black: hsl(0, 0%, 20%); //#333333
$black: hsl(0, 0%, 14%); //#242424
$grey: hsl(0, 0%, 51%); //#828282
$light-grey: hsl(200, 21%, 95%); //#F0F3F5FF
$orange: hsl(6, 100%, 62%);
$white: hsl(0, 0%, 100%);
$dark-turquoise: hsl(184, 96%, 28%);

$blue-gradient: linear-gradient(
	180deg,
	hsl(186, 60%, 75%) 0%,
	hsl(185, 55%, 78%) 100%
);
$card-shadow: 0 4px 80px rgba(0, 0, 0, 0.1);
$blue-shadow: 0 14px 64px rgba(70, 161, 209, 0.15);
$dark-blue-shadow: 0 14px 64px rgba(70, 161, 209, 0.42);

// breakpoints
$xxlg: 81.25rem; //1300px
$xl: 75rem; //1200px
$lg: 64rem; //1024px
$xmd: 56.25rem; //900px
$md: 50.6875rem; //811px
$sm: 35rem; //560px
$xs: 25rem; // 400px

//borders
$border-radius: 5px;

//sliders
$slider-button-size: 3.75rem;

:root {
	--carousel-slide-width: min(30rem, calc(100vw - (15px * 2)));
}
