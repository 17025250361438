@import "../../../assets/styles/variables";

.footer {
	padding-block: 4.75rem 2.5rem;
	overflow: hidden;

	@media screen and (max-width: $sm) {
		padding-block: 3.75rem 2.5rem;
	}

	&__container {
		display: flex;

		@media screen and (max-width: $md) {
			flex-direction: column;
			row-gap: 3.25rem;
		}
		@media screen and (max-width: $sm) {
			row-gap: 2.25rem;
		}
	}

	&__left {
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		align-items: flex-start;
		margin-inline-end: 8rem;

		@media screen and (max-width: $lg) {
			margin-inline-end: 3rem;
		}

		&-content {
			block-size: 100%;
		}
	}

	&__socials {
		display: flex;
		grid-column-gap: 0.625rem;
		grid-row-gap: 0.625rem;
		margin-block-start: 1.875rem;
	}
	&__social {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;
		block-size: 2.5rem;
		border-radius: 50%;
		box-shadow: 1px 1px 60px 4px rgb(0 0 0 / 15%);
		color: $light-black;
		inline-size: 2.5rem;
		transition: background-color 300ms ease;

		&:hover {
			background-color: hsl(184, 93%, 41%);
			color: hsl(0, 0%, 100%);

			& .insta-vector {
				fill: $primary-color;
			}
		}
	}
	&__info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: hsl(0, 0%, 95%);
		border-radius: 6px;
		color: $light-black;
		cursor: pointer;
		inline-size: 17.5rem;
		margin-block-start: 3.125rem;
		max-inline-size: 17.5rem;
		padding-block: 1rem;
		padding-inline: 1.5rem;
		text-decoration: none;
		transition: background-color 300ms ease;

		@media screen and (max-width: $md) {
			margin-block: 1.875rem;
			margin-inline: 0;
		}

		&-text {
			font-size: 0.875rem;
			font-weight: 500;
			letter-spacing: -0.5px;
			line-height: 140%;
			transition: color 300ms ease;
		}

		&:hover {
			background-color: $primary-color;
			color: $white;
		}
	}
	&__company {
		display: block;
	}
	&__text {
		color: hsl(0, 0%, 51%);
		font-size: 0.75rem;
		letter-spacing: -0.5px;
		line-height: 200%;
		margin-block-end: 1rem;

		&:last-child {
			margin-block-end: 0;
		}
		&--bold {
			color: $black;
			font-weight: 600;
		}
	}
	&__nav {
		display: flex;
		justify-content: space-between;
		list-style-type: none;
		margin-block-end: 3.125rem;
		padding-inline-start: 0;

		@media screen and (max-width: $md) {
			flex-wrap: wrap;
			justify-content: initial;
			gap: 1.5625rem;
		}
		@media screen and (max-width: $sm) {
			margin-block-end: 1.875rem;
		}
		&-link {
			color: $light-black;
			font-size: 0.875rem;
			font-weight: 600;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
}

.navbar {
	&__link {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-inline-end: 0.625rem;
		transition: color 200ms ease;
		& svg {
			transition: transform 0.3s ease;
		}
		&.js--active {
			color: $primary-color;
			& svg {
				transform: rotate(270deg);
			}
		}
		&:hover {
			color: $primary-color;
		}
		&-text {
			margin-inline-end: 0.625rem;
			&--uppercase {
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: -0.5px;
			}
		}
		&--regularcase {
			text-transform: none;
			font-weight: 500;
		}
	}
	&__dd {
		position: relative;
		margin-inline: auto;
		text-align: start;
	}
	&__dd-link {
		padding: 0;
		color: #232323;
		font-size: 0.875rem;
		font-weight: 400;
		display: block;
		margin-block-end: 1rem;
		transition: color 200ms ease;
		white-space: nowrap;
		&:hover {
			color: $primary-color;
		}
		&:last-child {
			margin-block-end: 0;
		}
	}
	&__dd-list {
		position: absolute;
		opacity: 0;
		display: none;
		transition: opacity 0.3s ease;
	}
}

.tippy-box{
	padding: 1.875rem;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow: 1px 1px 80px 4px rgb(0 0 0 / 10%);
	inset-block-start: 2rem;
	inset-inline: auto 0;
	min-inline-size: 100%;
}
