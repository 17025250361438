.meet {
	@media screen and (max-width: $sm) {
		padding-block: 40px;
		padding-inline: 0;
		border-bottom: none;
	}
	
	&__container {
		display: grid;
		gap: 40px 77px;
		grid-template-areas:
			"heading description"
			"photo list";
		grid-template-columns: 431fr 488fr;
		@media screen and (max-width: $md) {
			gap: 30px 32px;
		}
		@media screen and (max-width: 640px) {
			grid-template-areas:
				"photo"
				"heading"
				"description"
				"list";
			grid-template-columns: 1fr;
			row-gap: 25px;
		}
	}

	&__heading {
		margin: 0;
		grid-area: heading;
		text-align: start;
		@media screen and (max-width: $md) {
			font-size: 36px;
			line-height: 120%;
		}
		@media screen and (max-width: $sm) {
			inline-size: 100%;
			max-inline-size: 500px;
		}
	}

	&__description {
		grid-area: description;
		@media screen and (max-width: $md) {
			font-size: 16px;
			line-height: 200%;
		}
		@media screen and (max-width: $sm) {
			inline-size: 100%;
			max-inline-size: 500px;
			font-size: 1rem;
			line-height: 194%;
		}

	}

	&__image {
		border-radius: $border-radius;
		grid-area: photo;
		inline-size: 100%;
		block-size: 100%;
		object-fit: cover;
		@media screen and (max-width: $sm) {
			aspect-ratio: 1.5;
		}
		@media screen and (max-width: $xs) {
			aspect-ratio: 1.38;
		}
	}

	&__list {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		grid-area: list;
		row-gap: 15px;
		//@media screen and (max-width: $md) {
		//	row-gap: 10px;
		//}
		@media screen and (max-width: $sm) {
			margin-top: 10px;
			row-gap: 20px;
		}
	}

	&__list-marker {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		background-color: $white;
		block-size: 60px;
		border-radius: 50%;
		box-shadow: 0 4px 60px rgba(0, 0, 0, 0.1);
		color: $primary-color;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		inline-size: 60px;
		line-height: 100%;
	}

	&__list-item {
		display: flex;
		align-items: center;
		column-gap: 20px;
	}

	&__list-item-name {
		flex-grow: 1;
		color: $light-black;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.5px;
		line-height: 180%;
	}

	&__banner-container {
		padding-block-end: 0;
		@media screen and (max-width: $sm) {
			padding-block: 60px 0;
		}
	}
}
