@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
	font-family: "Poppins", "Arial", sans-serif;
	line-height: 1.5;
	min-block-size: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
}

html {
	font-size: 100%;
}

:focus-visible {
	outline: 2px solid $black;
	outline-offset: 3px;
	transition: all 0.3s ease;
}

::-webkit-scrollbar-track {
	background-color: $white;
}

::-webkit-scrollbar {
	background-color: $white;
	inline-size: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: $primary-color;
}

* {
	scrollbar-color: $primary-color $white;
	scrollbar-width: thin;
}

.section {
	border-block-end: 1px solid hsl(0, 0%, 88%);
	padding-block: 4rem;

	@media screen and (max-width: $md) {
		padding-block: 2.5rem;
	}

	&--hidden {
		@media screen and (max-width: $md) {
			overflow: hidden;
		}
	}

	&--min {
		padding-block: 6.25rem;
		@media screen and (max-width: $md) {
			padding-block: 4rem;
		}
	}

	&--blue {
		background-image: $blue-gradient;
	}

	&--first {
		@media screen and (max-width: $md) {
			padding-block-start: 2.5rem;
		}
	}
	&--large-padding {
		padding-block-end: 10.9375rem;
	}
	&--no-border {
		border-block-end: none;
	}
}

.inner-module {
	padding-block-start: 7.5rem;
	@media screen and (max-width: $md) {
		padding-block-start: 2.5rem;
	}
}

.container {
	inline-size: 100%;
	margin-block: 0;
	margin-inline: auto;
	max-inline-size: 76.875rem;
	padding-block: 0;
	padding-inline: 0.938rem;
	
	@media screen and (max-width: $md) {
		padding-inline: 1.875rem;
	}
	
	@media screen and (max-width: $sm) {
		padding-inline: 0.9375rem;
	}
	
	&.container--min {
		max-inline-size: 64.125rem;
	}
}

.heading {
	color: $light-black;
	font-weight: 600;

	&--xl {
		font-size: 4.5rem;
		letter-spacing: -5px;
		line-height: 110%;
		margin-block-end: 1.25rem;
		@media screen and (max-width: $lg) {
			font-size: 3.7rem;
		}
		@media screen and (max-width: $sm) {
			font-size: 3.125rem;
			letter-spacing: -3.2px;
		}
	}

	&--lg {
		color: $light-black;
		font-size: 2.25rem;
		letter-spacing: -2px;
		line-height: 120%;
		margin-block-end: 3.75rem;
		text-align: center;
		@media screen and (max-width: $sm) {
			font-size: 2rem;
		}
	}

	&--md {
		font-size: 1.5rem;
		line-height: 140%;
	}

	&--sm {
		font-size: 1.125rem;
		letter-spacing: -0.5px;
		line-height: 140%;
	}

	&--xsm {
		font-size: 0.875rem;
		letter-spacing: -0.5px;
		line-height: 100%;
	}

	&--center {
		text-align: center;
	}
}

.button {
	display: flex;
	align-items: center;
	background-color: $primary-color;
	border-radius: $border-radius;
	color: $white;
	cursor: pointer;

	font-size: 1rem;
	font-weight: 500;
	inline-size: max-content;
	letter-spacing: -0.03125rem;
	line-height: 99%;
	min-block-size: 3.5rem;
	padding-inline: 1.875rem;
	text-align: center;
	transition: background-color 0.3s ease, color 0.3s ease;

	&:hover {
		background-color: $dark-turquoise;
	}

	&--small {
		font-size: 0.875rem;
		line-height: 150%;
		min-block-size: 2.4375rem;
		@media screen and (max-width: 868px) {
			font-size: 0.75rem;
		}
	}

	&--low-paddings {
		padding-inline: 1.25rem;
	}

	&--big-paddings {
		padding-inline: 2.5rem;
	}

	&--background-white {
		background-color: $white;
		color: $black;

		&:hover {
			color: $white;
		}
	}

	&--background-grey {
		background-color: $light-grey;
		color: $black;
		margin-block-end: 2.5rem;
		@media screen and (max-width: $md) {
			margin-block-end: 1.25rem;
		}

		&:hover {
			background-color: $primary-color;
			color: $white;
		}
	}

	&--outline {
		background-color: transparent;
		color: $primary-color;
		outline: 1px solid $primary-color;
		&:hover {
			background-color: $primary-color;
			color: $white;
		}
	}

	&--fit {
		inline-size: fit-content;
	}
}

.paragraph {
	color: $grey;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: -0.03125rem;
	line-height: 200%;

	@media screen and (max-width: $lg) {
		font-size: 0.875rem;
		line-height: 180%;
	}

	&--sm {
		font-size: 0.875rem;
		line-height: 160%;
	}
}

.arrow {
	margin-inline-start: 0.5rem;
	&--before {
		margin-inline: 0 0.5rem;
	}
	&--no-margin {
		margin: 0;
	}
}

.divider {
	background: $light-grey;
	block-size: 1px;
	inline-size: 100%;
}

.tag {
	display: flex;
	align-items: center;
	color: $white;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: -0.5px;
	line-height: 1.3125rem;
	opacity: 0.6;

	& .circle {
		margin-inline-end: 0.3125rem;
	}
	&--grey {
		color: $light-black;
	}
	&--small {
		font-size: 0.875rem;
		margin-block-end: 1.25rem;
		@media screen and (max-width: $sm) {
			margin-block-end: 0.9375rem;
		}
	}
}

.video-duration {
	z-index: 3;
	padding: 0.625rem;
	background-color: $white;
	border-radius: $border-radius;
	color: $light-black;
	font-size: 0.875rem;
	font-weight: 500;
	letter-spacing: -0.5px;
	line-height: 100%;
	max-inline-size: max-content;
}

.play {
	position: relative;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	block-size: 2.5rem;
	border-radius: 50%;
	inline-size: 2.5rem;
	margin-block-end: 1rem;
	transition: background-color 0.3s ease 0s;

	&:hover {
		background-color: $primary-color;
		color: $white;
	}
}

.section-grid {
	display: grid;
	grid-column-gap: 1.25rem;
	grid-template-columns: 1fr 1fr;
	margin-block-end: 5.25rem;

	@media screen and (max-width: $md) {
		grid-row-gap: 1rem;
		grid-template-columns: 1fr;
	}

	@media screen and (max-width: $sm) {
		margin-block-end: 2.5rem;
	}

	&--margin-top {
		margin-block-start: 7.5rem;
		@media screen and (max-width: $sm) {
			margin-block-start: 5rem;
		}
	}

	&__heading {
		margin-block-end: 0;
		text-align: initial;
	}

	&__paragraph {
		&--right {
			font-size: 0.875rem;
			letter-spacing: -0.5px;
			line-height: 200%;
			margin-inline-start: auto;
			@media screen and (max-width: $md) {
				margin-inline-start: initial;
			}
		}
		&--full-width {
			inline-size: 100%;
			letter-spacing: -0.5px;
			line-height: 200%;
			max-inline-size: none;
		}
		max-inline-size: 24.125rem;
		@media screen and (max-width: $sm) {
			line-height: 200%;
		}
	}
}

.swiper {
	block-size: 100%;
	inline-size: 100%;
}

.slider-button {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	justify-content: center;
	background-color: $light-grey;
	block-size: $slider-button-size;
	border-radius: 50%;
	cursor: pointer;
	inline-size: $slider-button-size;
	transition: background-color 0.3s ease 0s;

	&--white {
		background-color: $white;
	}

	&.swiper-button-disabled {
		opacity: 0.5;
		&:hover {
			background-color: $light-grey;
			color: $light-black;
		}
	}

	&:hover {
		background-color: $primary-color;
		color: $white;
	}
}

.link {
	color: $primary-color;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: -0.5px;
	line-height: 100%;
	text-decoration-line: underline;
}

.banner {
	padding: 3.125rem;
	background-image: url("~/src/assets/images/banner-building.png"),
		linear-gradient(180deg, hsl(186, 60%, 75%), hsl(185, 55%, 78%));
	background-position: bottom 0 right 0, 0 0;
	background-repeat: no-repeat, repeat;
	background-size: auto, auto;
	border-radius: $border-radius;

	@media screen and (max-width: $md) {
		background-position: bottom 0 right -73%, 0 0;
	}

	@media screen and (max-width: $sm) {
		min-block-size: 28.6875rem;
		padding-block: 2.5rem;
		padding-inline: 1.25rem;
	}

	@media screen and (max-width: $sm) {
		background-position: bottom -1.3125rem right 111%;
	}

	&--roofs {
		background-image: url("~/src/assets/images/roofs-right.png"),
			linear-gradient(180deg, hsl(186, 60%, 75%), hsl(185, 55%, 78%));
		@media screen and (max-width: $md) {
			background-size: 538px auto, auto;
		}
		@media screen and (max-width: $sm) {
			background-size: 130%, auto;
		}
		&-left {
			background-image: url("~/src/assets/images/roof-mask.png"),
				linear-gradient(180deg, hsl(186, 60%, 75%), hsl(185, 55%, 78%));
			background-position: bottom 0 right 0, 0 0;
			@media screen and (max-width: $md) {
				background-position: bottom 0 right -73%, 0 0;
			}
			@media screen and  (max-width: $sm){
				background-position: bottom 0 right 0, 0 0;
			}
		}
	}

	&__container {
		padding-block: 3.75rem;
		&--big-padding {
			padding-block: 6.25rem 0;
		}
	}

	&__heading {
		margin-block-end: 0;
		text-align: initial;
		&--small {
			max-inline-size: 21.25rem;
		}
	}

	&__text {
		color: $black;
		font-size: 0.875rem;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 180%;
		margin-block: 0.9375rem 1.875rem;
		@media screen and (max-width: $md) {
			max-inline-size: 20rem;
		}

		@media screen and (max-width: $sm) {
			margin-block: 0.9375rem 1.875rem;
		}
	}

	&__button {
		margin-block-start: 1.875rem;
	}
}

.banner-right {
	background-position: bottom -1.25rem left 0, 0 0;
	@media screen and (max-width: $md) {
		background-position: bottom -1.25rem left -25%, 0 0;
	}
	@media screen and (max-width: $sm) {
		background-position: bottom -1.25rem left 0, 0 0;
	}
	&__content {
		margin-inline-start: auto;
		max-inline-size: 21.25rem;
		@media screen and (max-width: $sm) {
			margin-inline-start: initial;
		}
	}
}

.color-primary {
	color: $primary-color;
	&--block {
		display: block;
		@media screen and (max-width: $md) {
			display: initial;
		}
	}
}

.color-orange {
	color: $orange;
}

.faq {
	&__icon {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $light-grey;
		block-size: 3.75rem;
		border-radius: 50%;
		inline-size: 3.75rem;
		inset-block-start: 50%;
		inset-inline-end: 0;
		transform: translateY(-50%);
		transition: transform 0.3s ease 0s;

		& .arrow {
			margin: 0;
		}
	}

	&__content {
		overflow: hidden;
		block-size: 0;
		opacity: 0;
		padding-block-start: 0;
		padding-inline-end: 4.0625rem;
		transition: opacity 500ms ease, block-size 600ms ease;
	}
	&__question {
		position: relative;
		color: $black;
		font-size: 1.125rem;
		font-weight: 600;
		inline-size: 100%;
		letter-spacing: -0.5px;
		line-height: 140%;
		padding-inline-end: 4.0625rem;
		transition: color 0.3s ease, padding 0.3s ease;
	}
	&__link {
		position: relative;
		display: block;
		color: $primary-color;
		font-size: 1rem;
		font-weight: 600;
		inline-size: 100%;
		letter-spacing: -0.5px;
		line-height: 100%;
		margin-block-start: 1rem;
		padding-inline-end: 4.0625rem;
		text-decoration: underline;
		transition: color 0.3s ease 0s;
	}
	&__answer {
		max-inline-size: 38.125rem;
		transition: padding 0.3s ease 0s;
	}

	&__item {
		border-block-start: 1px solid hsl(0, 0%, 88%);
		cursor: pointer;
		padding-block: 2.9375rem;
		padding-inline: 0;
		&:last-child {
			border-block-end: 1px solid hsl(0, 0%, 88%);
		}
		&--border-bottom {
			border-block-end: 1px solid hsl(0, 0%, 88%);
		}

		@media screen and (max-width: $md) {
			padding-block: 2.5rem;
		}
		@media screen and (max-width: $sm) {
			padding-block: 1.875rem;
		}

		&.js--active {
			& .faq__content {
				overflow: visible;
				opacity: 1;
				transition: opacity 500ms ease, block-size 600ms ease;
			}

			& .faq__icon {
				transform: translateY(-50%) rotate(180deg);
			}

			& .faq__question {
				color: $primary-color;
				padding-block-end: 0.9375rem;
			}
		}
	}

	&__text {
		color: $black;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: -0.5px;
		line-height: 200%;
		padding-block-end: 0.625rem;
		&:last-child {
			padding-block-end: 0;
		}
	}
}

.btn-up {
	position: fixed;
	z-index: 4;
	background: $black;
	block-size: 3.75rem;
	border-radius: 50%;
	box-shadow: 0 7px 15px rgba(167, 167, 167, 0.1);
	color: $white;
	inline-size: 3.75rem;
	inset-block-end: 50px;
	inset-inline-end: 40px;
	opacity: 0;
	transition: color 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
	&.js--show {
		opacity: 1;
	}
	@media screen and (max-width: $md) {
		inset-block-end: 1.875rem;
		inset-inline-end: 0.9375rem;
	}
	&:hover {
		background-color: $primary-color;
	}
}

.page-overlay {
	position: fixed;
	display: none;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	z-index: 49;
	background-color: hsla(0, 0%, 100%, 0.5);
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	&--showed {
		display: block;
	}
	&--full-opacity {
		opacity: 1;
		transition: opacity 0.5s ease-in-out;
	}
}
