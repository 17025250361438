.thrive {
	position: relative;
	overflow: hidden;
	@media screen and (max-width: $xxlg) {
		padding-block-end: 150px
	}
	@media screen and (max-width: $md) {
		padding-block-start: 2.5rem;
		padding-block-end: 10rem;
		padding-inline: 0;
	}

	&__heading-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-block: 0 2.5rem;
		margin-inline: 0;
		@media screen and (max-width: $md) {
			margin-block-end: 21px;
		}
	}

	&__heading {
		margin-block: 0 1.875rem;
		margin-inline: 0;
		text-align: center;
	}

	&__subheading {
		margin-block: 0 3.75rem;
		margin-inline: 0;
		max-inline-size: 46.875rem;
		text-align: center;
		@media screen and (max-width: $md) {
			font-size: 16px;
			line-height: 200%;
			margin-block-end: 2.125rem;
		}
	}

	&__slider-heading {
		letter-spacing: -0.5px;
		@media screen and (max-width: $md) {
			font-size: 1.3125rem;
		}
	}
}

.thrive-slider {
	position: relative;
	overflow: hidden;

	&__container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: hsl(0, 0%, 96%);
		box-shadow: $card-shadow;
		inline-size: 100%;
	}

	&__pagination {
		display: none;
		@media  screen and (max-width: $xxlg) {
			position: relative !important;
			display: flex;
			bottom: unset !important;
		}

		& .swiper-pagination-bullet {
			width: 6px;
			height: 6px;
			background: #e0e0e0;
			opacity: 1;
			margin-inline: 0.1875 !important;
			&-active {
				background: $black;
			}
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
	}

	&__slide {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		padding: 1.875rem;
		background-color: $white;
		border-radius: $border-radius;
		row-gap: 1.6rem;
		@media screen and (max-width: $md) {
			padding: 1.25rem 1.25rem 2.5rem 1.25rem;
			row-gap: 1.3rem;
		}
	}

	&__slide-image {
		aspect-ratio: 1;
		border-radius: $border-radius;
		inline-size: 100%;
		object-fit: cover;
		@media screen and (max-width: $md){
			aspect-ratio: 1.15;
		}
	}

	&__slide-heading {
		inline-size: 100%;
	}

	&__slide-button{
		inline-size: max-content;
		inline-size: 0 1.0625rem;
	}

	&__navigation {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		z-index: 1;
		block-size: max-content;
		inline-size: 100%;
		@media screen and (max-width: $xxlg) {
			align-items: center;
			column-gap: 1.6875rem;
			flex-shrink: 0;
			bottom: -6.25rem;
			inline-size: max-content;
		}
	}

	&__button {
		position: relative;
		z-index: 2;
		box-shadow: $dark-blue-shadow;
		@media screen and (max-width: $xxlg) {
			background-color: hsl(200, 21%, 95%);
			box-shadow: none;
		}
		&-next {
			inset-inline-end: -2.5rem;
			@media screen and (max-width: $xxlg) {
				inset: unset;
			}
		}
		&-prev {
			inset-inline-start: -2.5rem;
			@media screen and (max-width: $xxlg) {
				inset: unset;
			}
		}
	}

}

