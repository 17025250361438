@import "./src/components/sliders/reviews-slider/reviews-slider";

.reviews {
	&__container{
		@media screen and (max-width: $md) {
			position: relative;
		}
	}
	&__title-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-block: 0 2.5rem;
		margin-inline: 0;
	}

	&__heading {
		text-align: center;
		position: relative;
		z-index: 2;
		@media screen and (max-width: $md){
			text-align: left;
		}
	}

	&__stars {
		display: flex;
		column-gap: 10px;
	}

	&__stars-icon {
		margin: 0;
		background-color: hsl(43, 100%, 50%);
		block-size: 1.25rem;
		inline-size: 7rem;
		mask-image: url("/src/assets/images/stars-review.svg");
		mask-repeat: no-repeat;
		mask-size: contain;
		transform: translate(0, 2px);//mask-image: url;
	}

	&__see-all-button {
		margin-block: 3.5rem 0;
		margin-inline: auto;
	}
}
