.calculator {
	display: flex;
	padding: 3.75rem;
	background-color: hsl(0, 0%, 100%);
	border-radius: $border-radius;
	box-shadow: 1px 1px 80px 4px rgba(0, 0, 0, 0.1);
	margin-block-end: 4rem;
	@media screen and (max-width: $xmd) {
		flex-direction: column;
		padding: 2.5rem;
	}

	@media screen and (max-width: $md) {
		flex-direction: column;
		margin-block-end: 3.75rem;
		padding-block: 2.5rem;
		padding-inline: 0.938rem;
	}

	&__fields {
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		-webkit-box-flex: 1;
		inline-size: 100%;
		margin-inline-end: 3.75rem;
	}

	&__graphics {
		flex: 1;
		max-inline-size: 24.25rem;
		@media screen and (max-width: $xmd) {
			margin-block-start: 2.5rem;
			margin-inline: auto;
		}
		@media screen and (max-width: $sm) {
			max-inline-size: 100%;
		}
	}

	&__prices {
		display: flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		grid-column-gap: 1.25rem;
		grid-row-gap: 1.25rem;
		margin-block-end: 2.5rem;
		@media screen and (max-width: $sm) {
			flex-direction: column;
		}
	}

	&__price {
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		background-color: hsl(200, 21%, 95%);
		border-radius: 6px;
		-webkit-box-flex: 1;
		color: hsl(0, 0%, 14%);
		padding-block: 1.125rem;
		padding-inline: 1.375rem;
		@media screen and (max-width: $sm) {
			text-align: center;
		}
	}

	&__price-label {
		font-size: 0.688rem;
		margin-block-end: 0.625rem;
	}

	&__price-number {
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 99%;
	}
}

.calc-wrapper {
	margin-block-end: 7.5rem;
}

.calc-wrp__container {
	inline-size: 90vw;
	margin-inline: auto;
	max-inline-size: 62.25rem;
}

@media (max-width: 64rem) {
	.calc-wrp__container {
		inline-size: auto;
		max-inline-size: 62.25rem;
	}
}

.calc-wrp input {
	color: hsl(0, 0%, 0%);
	outline: none;
}

.calc-wrp input[type="submit"] {
	cursor: pointer;
}

.calc-wrp__btn-calc {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: hsl(184, 93%, 41%);
	block-size: 3.5rem;
	color: hsl(0, 0%, 100%) !important;
	cursor: pointer;
	inline-size: 100%;
	outline: none;
	padding-inline: 1.25rem;
	transition: all 0.3s;
}

@media (max-width: 62rem) {
	.calc-wrp__inner-line {
		flex-wrap: wrap;
	}

	.calc-wrp input {
		inline-size: 100%;
	}
}

@media (max-width: 48rem) {
	.calc-wrp {
		margin: 0.5rem;
	}
}

.calculator {

}

.calculator__price-label {
	font-size: 0.6875rem;
	letter-spacing: -0.03125em;
	margin-block-end: 0.625rem;
}

@media (max-width: 75rem) {
	.calculator__fields {
		margin-inline-end: 1.75rem;
	}
}

@media (max-width: 75rem) {
	.calculator {
		padding: 1.75rem;
	}
}

@media (max-width: 62rem) {
	.calculator {
		padding: 1.2rem;
	}
}

@media (max-width: 62rem) {
	.calculator__prices {
		inline-size: 100%;
	}
}



.form {
	margin: 0;
	block-size: 100%;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	&__radiobuttons {
		display: flex;
		grid-column-gap: 0.375rem;
		grid-row-gap: 0.375rem;
	}
	&__layout {
		display: grid;
		align-items: stretch;
		grid-auto-columns: 1fr;
		grid-column-gap: 1.2588rem;
		grid-row-gap: 0;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
		justify-items: stretch;
		margin-block-end: 0;
		@media screen and (max-width: $sm){
			grid-template-columns: 1fr;
		}
	}
	&__item{
		margin-block-end: 1.875rem;
		&.hide{
			display: none;
			overflow: hidden;
			block-size: 0;
			inline-size: 0;
			opacity: 0;
			transition: all 0.2s;
		}
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0;
		block-size: 100%;
	}
}

.legend {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	margin-block-start: 2.5rem;

	&__item{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&__text{
		color: hsl(0, 0%, 51%);
		font-size: 0.875rem;
	}
	&__color {
		block-size: 1.25rem;
		border-radius: 50%;
		inline-size: 1.25rem;
		margin-block-end: 0.313rem;
	}
}

.chart {
	inline-size: auto;
	&__text{
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		text-align: center;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		@media screen and (max-width: $sm){
			inset-block-start: calc(50% - 2rem);
		}
	}
	&__description{
		color: hsl(0, 0%, 20%);
		font-size: 0.875rem;
		margin-block-end: 0.313rem;
	}
	&__price{
		color: hsl(184, 93%, 41%);
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 140%;
		&::before {
			content: "$";
			inset-inline-start: 0;
			margin-inline-end: 0.3125em;
		}
	}
}

.condition-item {
	margin-block-end: 0;
}

.radio {
	position: relative;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	background-color: hsl(200, 21%, 95%);
	block-size: 3.5rem;
	border-radius: $border-radius;
	color: hsl(0, 0%, 74%);
	&__label {
		position: absolute;
		display: inline-block;
		cursor: pointer;
		font-size: 1rem;
		font-weight: 500;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		margin-block-end: 0;
		transform: translate(-50%, -50%);
		transition: all .2s;
	}
	&__icon {
		position: absolute;
		border-style: none;
		background-color: transparent;
		block-size: 3.5rem;
		border-radius: 6px;
		color: hsl(0, 0%, 74%);
		cursor: pointer;
		inline-size: 100%;
		inset: 0%;
		margin-block-start: 0;
		margin-inline-start: 0;
		transition: all .2s;
	}
}

.calc-wrp label.radio input[type="radio"]:checked~.radio__icon {
	background-color: hsl(0, 0%, 20%);
	color: hsl(0, 0%, 100%);
	transition: all .2s;
}

.calc-wrp label.radio input[type="radio"]:checked~.radio__label {
	color: hsl(0, 0%, 100%);
}

.input {
	display: block;
	border: 0 none hsl(0, 0%, 0%);
	background-color: hsl(200, 21%, 95%);
	block-size: 3.5rem;
	border-radius: $border-radius;
	color: hsl(0, 0%, 20%);
	font-size: 1rem;
	font-weight: 500;
	inline-size: 100%;
	line-height: 100%;
	margin-block-end: 0.625rem;
	padding-block: 0.5rem;
	padding-inline: 0.9375rem;
}

.label {
	display: block;
	color: rgba(92, 92, 92, 0.6);
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: -0.5px;
	margin-block-end: 0.625rem;
}

.checkbox{
	&__label{
		display: inline-block;
		color: rgba(92, 92, 92, 0.6);
		cursor: pointer;
		font-size: 0.813rem;
		font-weight: normal;
		line-height: 99%;
		margin-block-end: 0;
	}
}

@media (max-width: 62rem) {
	.form__item {
		margin-block-end: 0.875rem;
	}
}

.chart__price {
	position: relative;
}

.chart__price:before {
	content: "$";
	inset-inline-start: 0;
	margin-inline-end: 0.3125em;
}

.checkbox-inline {
	position: relative;
	display: flex;
	align-items: center;
	margin-block: 0.375rem 0;
	padding-inline-start: 1.625rem;
}

.checkbox-inline input[type="checkbox"] {
	position: absolute;
	z-index: -1;
	inset-inline-start: 0;
	opacity: 0;
}

.checkbox-inline input[type="checkbox"]:checked ~ .checkbox__icon {
	border: 1px solid hsl(184, 93%, 41%);
	background-color: hsl(184, 93%, 41%);
	background-image: url("https://dev.wowmi.us/companies/prosperity/advisor/branch/svg/check.svg");
	background-size: auto;
}

.checkbox__label {
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
}

.checkbox__icon {
	position: absolute;
	z-index: 1;
	border: 1px solid hsl(0, 0%, 74%);
	background-position: 50%;
	background-repeat: no-repeat;
	block-size: 1rem;
	border-radius: 0.125rem;
	cursor: pointer;
	inline-size: 1rem;
	inset-inline-start: 0;
	margin-block-start: 0;
	margin-inline-end: 0.625rem;
	opacity: 1;
}

label.radio input[type="radio"] {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

label.radio input[type="radio"]:checked ~ .radio__icon {
	background-color: hsl(0, 0%, 20%);
	color: hsl(0, 0%, 100%);
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.radio__icon {
	-o-transition: all 0.2s;
	transition: all 0.2s;
}

.calculator__graphics {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.chart__donut {
	position: relative;
	display: flex;
	flex-direction: column;
	block-size: 100%;
}

.chart__donut-wrp {
	flex: 1;
}

.chart #donutBox {
	position: relative;
	z-index: 3;
}

.chart__text {
	z-index: 1;
	inset-block-start: calc(50% - 2.5rem);
}

@media (max-width: 29.9375rem) {
	.chart__text {
		inline-size: 7.8125rem;
	}
}

@media (max-width: 29.9375rem) {
	.chart__price {
		font-size: 1rem;
	}
}

@media (max-width: 29.9375rem) {
	.legend {
		align-items: center;
		justify-content: center;
	}
}

@media screen and (max-width: 479px) {
	.chart__donut-wrp {
		max-width: 100%;
	}
}
