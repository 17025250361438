* {
	box-sizing: border-box;
	padding: 0;
	border-style: none;
	margin: 0;
	background-color: transparent;
	border-radius: 0;
	color: inherit;
	font-family: inherit;
	list-style: none;
	text-decoration: inherit;
}

html,
body {
	color: hsl(0, 0%, 20%);
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 100%;
}

button{
	cursor: pointer;
}

