.stars {
	display: flex;
	align-items: center;
	column-gap: 0.625rem;

	&__icon {

	}

	&__score {
		font-weight: 600
	}
}

